// import { NavLink } from 'react-router-dom';

function SectionServices1() {
  return (
    <>
      <div className="section-full p-t110 p-b80 sx-our-services-outer sx-bg-light">
        <div className="container">
          {/* TITLE START */}
          <div className="section-head center">
            <div className="sx-head-s-title">Our Services</div>
            <div className="sx-head-l-title">
              <h2 className="sx-title">Sophisticated Knowledge For Rapidly Emerging Legal service of Our IT Solution!</h2>
            </div>
          </div>
          {/* TITLE END */}
          <div className="section-content">
            <div className="sx-our-services-bx">
              <div className="row">
                {/* Block one */}
                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-service-bx-1">
                    <div className="sx-icon-box-wraper">
                      <div className="sx-service-bx-icon scale-in-center">
                        <span className="sx-text-primary"><i className="flaticon-security" /></span>
                      </div>
                      <div className="icon-content">
                        <h4 className="sx-tilte">Expertise and Experience</h4>
                        <p>Highlight your company's expertise in the IT industry, including the number of years in business, key accomplishments,
                                                    and specialised skills or certifications. Showcase your team's qualifications, industry knowledge, and experience working with clients in various sectors.</p>
                        {/*<div className="sx-center-btn">*/}
                        {/*    <NavLink to="/services/detail" className="site-button-circle">*/}
                        {/*        <i className="fa fa-long-arrow-right" />*/}
                        {/*    </NavLink>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Block Two */}
                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-service-bx-1">
                    <div className="sx-icon-box-wraper">
                      <div className="sx-service-bx-icon scale-in-center">
                        <span className="sx-text-primary"><i className="flaticon-coding" /></span>
                      </div>
                      <div className="icon-content">
                        <h4 className="sx-tilte">Customised Solutions</h4>
                        <p>Emphasise your ability to provide customised IT solutions tailored to each client's unique needs and challenges.
                                                    Showcase successful case studies or examples of how you've solved specific problems for clients through innovative and personalised approaches.</p>
                        {/*<div className="sx-center-btn">*/}
                        {/*    <NavLink to="/services/detail" className="site-button-circle active">*/}
                        {/*        <i className="fa fa-long-arrow-right" />*/}
                        {/*    </NavLink>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Block Three */}
                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-service-bx-1">
                    <div className="sx-icon-box-wraper">
                      <div className="sx-service-bx-icon scale-in-center">
                        <span className="sx-text-primary"><i className="flaticon-profit" /></span>
                      </div>
                      <div className="icon-content">
                        <h4 className="sx-tilte">Technology Partnerships</h4>
                        <p>If applicable, mention any partnerships or collaborations with leading technology providers, such as Microsoft, AWS,
                                                    Cisco, Oracle, etc. Highlight how these partnerships enable you to deliver cutting-edge solutions and stay at the forefront of industry trends.</p>
                        {/*<div className="sx-center-btn">*/}
                        {/*    <NavLink to="/services/detail" className="site-button-circle">*/}
                        {/*        <i className="fa fa-long-arrow-right" />*/}
                        {/*    </NavLink>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Block Four */}
                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-service-bx-1">
                    <div className="sx-icon-box-wraper">
                      <div className="sx-service-bx-icon scale-in-center">
                        <span className="sx-text-primary"><i className="flaticon-employee" /></span>
                      </div>
                      <div className="icon-content">
                        <h4 className="sx-tilte">Comprehensive Services</h4>
                        <p>Outline the range of IT services you offer, such as software development, IT consulting, cloud solutions, cybersecurity,
                                                    data analytics, managed services, etc. Explain how your comprehensive services can address all aspects of a client's IT needs.</p>
                        {/*<div className="sx-center-btn">*/}
                        {/*    <NavLink to="/services/detail" className="site-button-circle">*/}
                        {/*        <i className="fa fa-long-arrow-right" />*/}
                        {/*    </NavLink>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Block Five */}
                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-service-bx-1">
                    <div className="sx-icon-box-wraper">
                      <div className="sx-service-bx-icon scale-in-center">
                        <span className="sx-text-primary"><i className="flaticon-technical-support" /></span>
                      </div>
                      <div className="icon-content">
                        <h4 className="sx-tilte">Quality Assurance</h4>
                        <p>Highlight your commitment to quality and excellence in delivering IT solutions. Mention any quality assurance processes, industry
                                                    I standards compliance, or certifications (e.g., ISO, CMMI) that demonstrate your commitment to delivering high- quality results.</p>
                        {/*<div className="sx-center-btn">*/}
                        {/*    <NavLink to="/services/detail" className="site-button-circle">*/}
                        {/*        <i className="fa fa-long-arrow-right" />*/}
                        {/*    </NavLink>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Block Six */}
                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-service-bx-1">
                    <div className="sx-icon-box-wraper">
                      <div className="sx-service-bx-icon scale-in-center">
                        <span className="sx-text-primary"><i className="flaticon-support" /></span>
                      </div>
                      <div className="icon-content">
                        <h4 className="sx-tilte">Client-Centric Approach</h4>
                        <p>Showcase your client-centric approach and commitment to building long-term partnerships Highlight testimonials,
                                                    client success stories, or customer satisfaction metrics to demonstrate the value and positive impact your services have had on clients' businesses.</p>
                        {/*<div className="sx-center-btn">*/}
                        {/*    <NavLink to="/services/detail" className="site-button-circle">*/}
                        {/*        <i className="fa fa-long-arrow-right" />*/}
                        {/*    </NavLink>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionServices1;