import Banner from '../../../sections/common/banner';
import SectionClients2 from '../../../sections/home/index2/section-clients2';
import { bannerData } from '../../../../globals/banner';

function Portfolio1Page() {
  return (
    <>
      <Banner _data={bannerData.portfolio1} />
      <SectionClients2 />
    </>
  );
}

export default Portfolio1Page;