import ItodoImage from '../../../elements/itodo-img';

function GeneralFAQContent() {
  return (
    <>
      <div className="aon-faq-content">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="sx-acdn-style-1-section">
              <div className="accordion sx-acdn-style-1" id="sf-faq-accordion">
                {/*One*/}
                <div className="accordion-item">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ1" aria-expanded="false">
                                        What should be listed on a business card?
                  </button>
                  <div id="FAQ1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                    <div className="accordion-body">
                                            Nullam pulvinar, enim ornare tristique vehicula, massa est eleifend velit, vitae laoreet leo diam vitae quam. Curabitur dignissim nisi sed libero pellentesque hendrerit. Morbi condimentum in elit in lobortis.
                    </div>
                  </div>
                </div>
                {/*Two*/}
                <div className="accordion-item">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ2" aria-expanded="true">
                                        What is the standard size of business cards?
                  </button>
                  <div id="FAQ2" className="accordion-collapse collapse show" data-bs-parent="#sf-faq-accordion">
                    <div className="accordion-body">
                                            Nullam pulvinar, enim ornare tristique vehicula, massa est eleifend velit pellentesque hendrerit. Morbi condimentum in elit in lobortis. Maurisvit velit erat. Nunc in urna sit amet nisi venenatis listed on a business card.
                    </div>
                  </div>
                </div>
                {/*Three*/}
                <div className="accordion-item">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ3" aria-expanded="false">
                                        What should be listed on a business card?
                  </button>
                  <div id="FAQ3" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                    <div className="accordion-body">
                                            Nullam pulvinar, enim ornare tristique vehicula, massa est eleifend velit pellentesque hendrerit. Morbi condimentum in elit in lobortis. Maurisvit velit erat. Nunc in urna sit amet nisi venenatis listed on a business card.
                    </div>
                  </div>
                </div>
                {/*Four*/}
                <div className="accordion-item">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ4" aria-expanded="false">
                                        How do I design my own business cards?
                  </button>
                  <div id="FAQ4" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                    <div className="accordion-body">
                                            Nullam pulvinar, enim ornare tristique vehicula, massa est eleifend velit pellentesque hendrerit. Morbi condimentum in elit in lobortis. Maurisvit velit erat. Nunc in urna sit amet nisi venenatis listed on a business card.
                    </div>
                  </div>
                </div>
                {/*Five*/}
                <div className="accordion-item">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ5" aria-expanded="false">
                                        Design my own business cards?
                  </button>
                  <div id="FAQ5" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                    <div className="accordion-body">
                                            Nullam pulvinar, enim ornare tristique vehicula, massa est eleifend velit pellentesque hendrerit. Morbi condimentum in elit in lobortis. Maurisvit velit erat. Nunc in urna sit amet nisi venenatis listed on a business card.
                    </div>
                  </div>
                </div>
                {/*Six*/}
                <div className="accordion-item">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ6" aria-expanded="false">
                                        What should be listed on a business card?
                  </button>
                  <div id="FAQ6" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                    <div className="accordion-body">
                                            Nullam pulvinar, enim ornare tristique vehicula, massa est eleifend velit pellentesque hendrerit. Morbi condimentum in elit in lobortis. Maurisvit velit erat. Nunc in urna sit amet nisi venenatis listed on a business card.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12">
            <div className="aon-faq-1-media">
              <ItodoImage src="images/faq-img-1.jpg" alt="#" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function HelpFAQContent() {
  return (
    <>
      <div className="aon-faq-content">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="sx-acdn-style-1-section">
              <div className="accordion sx-acdn-style-1" id="sf-faq-accordion-1">
                {/*One*/}
                <div className="accordion-item">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ1-1" aria-expanded="false">
                                        What should be listed on a business card?
                  </button>
                  <div id="FAQ1-1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion-1">
                    <div className="accordion-body">
                                            Nullam pulvinar, enim ornare tristique vehicula, massa est eleifend velit pellentesque hendrerit. Morbi condimentum in elit in lobortis. Maurisvit velit erat. Nunc in urna sit amet nisi venenatis listed on a business card.
                    </div>
                  </div>
                </div>
                {/*Two*/}
                <div className="accordion-item">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ2-1" aria-expanded="true">
                                        What is the standard size of business cards?
                  </button>
                  <div id="FAQ2-1" className="accordion-collapse collapse show" data-bs-parent="#sf-faq-accordion-1">
                    <div className="accordion-body">
                                            Nullam pulvinar, enim ornare tristique vehicula, massa est eleifend velit pellentesque hendrerit. Morbi condimentum in elit in lobortis. Maurisvit velit erat. Nunc in urna sit amet nisi venenatis listed on a business card.
                    </div>
                  </div>
                </div>
                {/*Three*/}
                <div className="accordion-item">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ3-1" aria-expanded="false">
                                        What should be listed on a business card?
                  </button>
                  <div id="FAQ3-1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion-1">
                    <div className="accordion-body">
                                            Nullam pulvinar, enim ornare tristique vehicula, massa est eleifend velit pellentesque hendrerit. Morbi condimentum in elit in lobortis. Maurisvit velit erat. Nunc in urna sit amet nisi venenatis listed on a business card.
                    </div>
                  </div>
                </div>
                {/*Four*/}
                <div className="accordion-item">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ4-1" aria-expanded="false">
                                        How do I design my own business cards?
                  </button>
                  <div id="FAQ4-1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion-1">
                    <div className="accordion-body">
                                            Nullam pulvinar, enim ornare tristique vehicula, massa est eleifend velit pellentesque hendrerit. Morbi condimentum in elit in lobortis. Maurisvit velit erat. Nunc in urna sit amet nisi venenatis listed on a business card.
                    </div>
                  </div>
                </div>
                {/*Five*/}
                <div className="accordion-item">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ5-1" aria-expanded="false">
                                        Design my own business cards?
                  </button>
                  <div id="FAQ5-1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion-1">
                    <div className="accordion-body">
                                            Nullam pulvinar, enim ornare tristique vehicula, massa est eleifend velit pellentesque hendrerit. Morbi condimentum in elit in lobortis. Maurisvit velit erat. Nunc in urna sit amet nisi venenatis listed on a business card.
                    </div>
                  </div>
                </div>
                {/*Six*/}
                <div className="accordion-item">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ6-1" aria-expanded="false">
                                        What should be listed on a business card?
                  </button>
                  <div id="FAQ6-1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion-1">
                    <div className="accordion-body">
                                            Nullam pulvinar, enim ornare tristique vehicula, massa est eleifend velit pellentesque hendrerit. Morbi condimentum in elit in lobortis. Maurisvit velit erat. Nunc in urna sit amet nisi venenatis listed on a business card.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12">
            <div className="aon-faq-1-media">
              <ItodoImage src="images/faq-img-2.jpg" alt="#" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function SectionFAQContent() {
  return (
    <>
      <div className="tab-content">
        {/*1*/}
        <div className="tab-pane fade show active" id="day_1" role="tabpanel">
          <GeneralFAQContent />
        </div>
        {/*2*/}
        <div className="tab-pane fade" id="day_2" role="tabpanel">
          <HelpFAQContent />
        </div>
      </div>
    </>
  );
}

export default SectionFAQContent;