import { NavLink } from 'react-router-dom';

function SectionServices2() {
  return (
    <>
      <div className="section-full p-t110 p-b80 sx-why-choose-outer sx-bg-light">
        <div className="container">
          {/* TITLE START */}
          <div className="section-head center">
            <div className="sx-head-s-title">Why Choose Us</div>
            <div className="sx-head-l-title">
              <h2 className="sx-title">Our Work Services</h2>
            </div>
          </div>
          {/* TITLE END */}
          <div className="section-content">
            <div className="owl-carousel sx-why-choose-carousel m-b30">
              {/*One block*/}
              <div className="item">
                <div className="sx-icon-bx-4">
                  <div className="sx-icon-box-wraper">
                    <div className="sx-icon-bx-icon ">
                      <span className="sx-text-primary"><i className="flaticon-monitor" /></span>
                    </div>
                    <div className="icon-content">
                      <NavLink to="/services/detail"><h4 className="sx-tilte">UX/UI &amp; Graphics </h4></NavLink>
                      <NavLink to="/services/detail" className="sx-why-ch-category">Real Specialist</NavLink>
                      <p>Etiam finibus lectus ac metus cursus luctus in id nibh. Morbi sit amet dui quis mentor’s pent. tellus quis iaculis interdum</p>
                      <NavLink className="sx-circle-btn" to="/services/detail"><i className="fa fa-long-arrow-right" /></NavLink>
                    </div>
                  </div>
                </div>
              </div>
              {/*One two*/}
              <div className="item">
                <div className="sx-icon-bx-4">
                  <div className="sx-icon-box-wraper">
                    <div className="sx-icon-bx-icon ">
                      <span className="sx-text-primary"><i className="flaticon-mouse-1" /></span>
                    </div>
                    <div className="icon-content">
                      <NavLink to="/services/detail"><h4 className="sx-tilte">Product develop</h4></NavLink>
                      <NavLink to="/services/detail" className="sx-why-ch-category">Branding Design</NavLink>
                      <p>Etiam finibus lectus ac metus cursus luctus in id nibh. Morbi sit amet dui quis mentor’s pent. tellus quis iaculis interdum</p>
                      <NavLink className="sx-circle-btn" to="/services/detail"><i className="fa fa-long-arrow-right" /></NavLink>
                    </div>
                  </div>
                </div>
              </div>
              {/*One three*/}
              <div className="item">
                <div className="sx-icon-bx-4">
                  <div className="sx-icon-box-wraper">
                    <div className="sx-icon-bx-icon ">
                      <span className="sx-text-primary"><i className="flaticon-layers" /></span>
                    </div>
                    <div className="icon-content">
                      <NavLink to="/services/detail"><h4 className="sx-tilte">UX/UI Design</h4></NavLink>
                      <NavLink to="/services/detail" className="sx-why-ch-category">Apps Design</NavLink>
                      <p>Etiam finibus lectus ac metus cursus luctus in id nibh. Morbi sit amet dui quis mentor’s pent. tellus quis iaculis interdum</p>
                      <NavLink className="sx-circle-btn" to="/services/detail"><i className="fa fa-long-arrow-right" /></NavLink>
                    </div>
                  </div>
                </div>
              </div>
              {/*One four*/}
              <div className="item">
                <div className="sx-icon-bx-4">
                  <div className="sx-icon-box-wraper">
                    <div className="sx-icon-bx-icon ">
                      <span className="sx-text-primary"><i className="flaticon-web-design" /></span>
                    </div>
                    <div className="icon-content">
                      <NavLink to="/services/detail"><h4 className="sx-tilte">Web Designer</h4></NavLink>
                      <NavLink to="/services/detail" className="sx-why-ch-category">Ecommerce</NavLink>
                      <p>Etiam finibus lectus ac metus cursus luctus in id nibh. Morbi sit amet dui quis mentor’s pent. tellus quis iaculis interdum</p>
                      <NavLink className="sx-circle-btn" to="/services/detail"><i className="fa fa-long-arrow-right" /></NavLink>
                    </div>
                  </div>
                </div>
              </div>
              {/*One five*/}
              <div className="item">
                <div className="sx-icon-bx-4">
                  <div className="sx-icon-box-wraper">
                    <div className="sx-icon-bx-icon ">
                      <span className="sx-text-primary"><i className="flaticon-monitor" /></span>
                    </div>
                    <div className="icon-content">
                      <NavLink to="/services/detail"><h4 className="sx-tilte">UX/UI &amp; Graphics </h4></NavLink>
                      <NavLink to="/services/detail" className="sx-why-ch-category">Real Specialist</NavLink>
                      <p>Etiam finibus lectus ac metus cursus luctus in id nibh. Morbi sit amet dui quis mentor’s pent. tellus quis iaculis interdum</p>
                      <NavLink className="sx-circle-btn" to="/services/detail"><i className="fa fa-long-arrow-right" /></NavLink>
                    </div>
                  </div>
                </div>
              </div>
              {/*One six*/}
              <div className="item">
                <div className="sx-icon-bx-4">
                  <div className="sx-icon-box-wraper">
                    <div className="sx-icon-bx-icon ">
                      <span className="sx-text-primary"><i className="flaticon-mouse-1" /></span>
                    </div>
                    <div className="icon-content">
                      <NavLink to="/services/detail"><h4 className="sx-tilte">Product develop</h4></NavLink>
                      <NavLink to="/services/detail" className="sx-why-ch-category">Branding Design</NavLink>
                      <p>Etiam finibus lectus ac metus cursus luctus in id nibh. Morbi sit amet dui quis mentor’s pent. tellus quis iaculis interdum</p>
                      <NavLink className="sx-circle-btn" to="/services/detail"><i className="fa fa-long-arrow-right" /></NavLink>
                    </div>
                  </div>
                </div>
              </div>
              {/*One seven*/}
              <div className="item">
                <div className="sx-icon-bx-4">
                  <div className="sx-icon-box-wraper">
                    <div className="sx-icon-bx-icon ">
                      <span className="sx-text-primary"><i className="flaticon-layers" /></span>
                    </div>
                    <div className="icon-content">
                      <NavLink to="/services/detail"><h4 className="sx-tilte">UX/UI Design</h4></NavLink>
                      <NavLink to="/services/detail" className="sx-why-ch-category">Apps Design</NavLink>
                      <p>Etiam finibus lectus ac metus cursus luctus in id nibh. Morbi sit amet dui quis mentor’s pent. tellus quis iaculis interdum</p>
                      <NavLink className="sx-circle-btn" to="/services/detail"><i className="fa fa-long-arrow-right" /></NavLink>
                    </div>
                  </div>
                </div>
              </div>
              {/*One eight*/}
              <div className="item">
                <div className="sx-icon-bx-4">
                  <div className="sx-icon-box-wraper">
                    <div className="sx-icon-bx-icon ">
                      <span className="sx-text-primary"><i className="flaticon-web-design" /></span>
                    </div>
                    <div className="icon-content">
                      <NavLink to="/services/detail"><h4 className="sx-tilte">Web Designer</h4></NavLink>
                      <NavLink to="/services/detail" className="sx-why-ch-category">Ecommerce</NavLink>
                      <p>Etiam finibus lectus ac metus cursus luctus in id nibh. Morbi sit amet dui quis mentor’s pent. tellus quis iaculis interdum</p>
                      <NavLink className="sx-circle-btn" to="/services/detail"><i className="fa fa-long-arrow-right" /></NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionServices2;