import ItodoImage from '../../../elements/itodo-img';
import { publicUrlFor } from '../../../../globals/constants';

function SectionAboutCompany1() {
  return (
    <>
      <div className="section-full  p-t110 p-b80 sx-bg-white sx-about-bx1-outer">
        <div className="container">
          <div className="section-content">
            <div className="sx-about-bx1-content-wrap">
              <div className="row">
                <div className="col-lg-6 col-md-12 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-about-bx1-media">
                    <div className="sx-dot-bx"><ItodoImage src="images/about/dotted-pic.png" alt="" /></div>
                    <div className="sx-ab-img-outer">
                      <div className="sx-ab-img-section-1-wrap">
                        <div className="sx-ab-video">
                          <a href="https://www.youtube.com/watch?v=c1XNqw2gSbU" className="mfp-video circle">
                            <i className="flaticon-play" />
                          </a>
                        </div>
                        <div className="sx-ab-img-section-1" style={{ backgroundImage: `url(${publicUrlFor('images/about/left-pic.png')})` }} />
                      </div>
                      <div className="sx-ab-img-section-2">
                        <ItodoImage src="images/about/p1.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-about-bx1-content">
                    <h2 className="sx-title">That's why we're list of favorites.</h2>
                    <span className="sx-title-2">At raimptech solution, our unwavering commitment to excellence, innovative solutions, and client-centric strategies make us the preferred choice for businesses
                                            in need of cutting-edge IT consulting and services. Discover how our expertise can propel our business forward.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionAboutCompany1;