import { publicUrlFor } from '../../../../globals/constants';
import ItodoImage from '../../../elements/itodo-img';

function SectionOurInfo1() {
  return (
    <>
      <div className="sx-mv-bx1-content-wrap">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="sx-about-bx1-content">
              <h2 className="sx-title">That's why we're list of favorites.</h2>
              <span className="sx-title-2">Nullam vel ligula lorem. Phasellus neque Etiam nequeretium.</span>
              <p>Our commitment to excellence, innovative approach, and client satisfaction sets us apart. Explore how we can elevate your business with tailored IT services.</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="sx-mv-bx1-media">
              <div className="sx-dot-bx-right"><ItodoImage src="images/about/dotted-pic.png" alt="image" /></div>
              <div className="sx-mv-img-outer">
                <div className="sx-mv-img-section-2">
                  <ItodoImage src="images/about/p1-1.jpg" alt="image" />
                </div>
                <div className="sx-mv-img-section-1-wrap">
                  <div className="sx-mv-video">
                    <a href="https://www.youtube.com/watch?v=c1XNqw2gSbU" className="mfp-video circle">
                      <i className="flaticon-play" />
                    </a>
                  </div>
                  <div className="sx-mv-img-section-1" style={{ backgroundImage: `url(${publicUrlFor('images/about/right-pic.jpg')})` }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionOurInfo1;