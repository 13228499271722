import ItodoImage from '../../../elements/itodo-img';
function SectionClients1() {
  return (
    <>
      <div className="section-full p-t110 p-b80 sx-bg-primary sx-client-logo-2-outer">
        <div className="container">
          <div className="section-content">
            <div className="client-logo-pic2-wrap">
              <div className="row justify-content-center grid-5 ">
                <div className="col-lg-2 col-md-4 col-sm-4 col-4">
                  <div  className="client-logo-pic2">
                    <ItodoImage src="images/client-logo/white/python.png" alt="" />
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-4 col-4">
                  <div className="client-logo-pic2">
                    <ItodoImage src="images/client-logo/white/reactjs.png" alt="" />
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-4 col-4">
                  <div className="client-logo-pic2">
                    <ItodoImage src="images/client-logo/white/node-js.png" alt="" />
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-4 col-4">
                  <div className="client-logo-pic2">
                    <ItodoImage src="images/client-logo/white/java.png" alt="" />
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-4 col-4">
                  <div className="client-logo-pic2">
                    <ItodoImage src="images/client-logo/white/database.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionClients1;