import { publicUrlFor } from '../../../../globals/constants';
import { NavLink } from 'react-router-dom';

function SectionWhyChooseUs1() {
  return (
    <>
      <div className="section-full p-t110 p-b80 sx-why-chooseus-outer sx-bg-light bg-cover" style={{ backgroundImage: `url(${publicUrlFor('images/background/bg-1.jpg')})` }}>
        <div className="container">
          {/* TITLE START */}
          <div className="section-head center">
            <div className="sx-head-s-title">Why Choose Us</div>
            <div className="sx-head-l-title">
              <h2 className="sx-title">Our Work Services</h2>
            </div>
          </div>
          {/* TITLE END */}
          <div className="section-content">
            <div className="sx-icon-bx-wrap">
              <div className="row">
                {/* Block one */}
                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-icon-bx-1">
                    <div className="sx-icon-box-wraper">
                      <div className="sx-icon-bx-icon ">
                        <span className="sx-text-primary"><img src="/assets/images/work-service/development.png"
                          alt="" height="64px" width="64px"/></span>
                      </div>
                      <div className="icon-content">
                        <NavLink to="/services/detail"><h4 className="sx-tilte">Software Development</h4></NavLink>
                        <p>Designing, developing, and deploying custom software solutions tailored to clients' specific requirements.
                                                    This includes web applications, mobile apps, enterprise software, CRM systems, e-commerce platforms, and more.</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Block Two */}
                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-icon-bx-1">
                    <div className="sx-icon-box-wraper">
                      <div className="sx-icon-bx-icon ">
                        <span className="sx-text-primary"><i className="flaticon-programmer" /></span>
                      </div>
                      <div className="icon-content">
                        <NavLink to="/services/detail"><h4 className="sx-tilte">IT Consulting</h4></NavLink>
                        <p>Providing expert advice, guidance, and strategic planning to help clients leverage technology effectively for business growth.
                                                    This may involve IT infrastructure assessment, digital transformation strategies, technology roadmap development, and IT project management.</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Block Three */}
                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-icon-bx-1">
                    <div className="sx-icon-box-wraper">
                      <div className="sx-icon-bx-icon ">
                        <span className="sx-text-primary"><i className="flaticon-positive-review" /></span>
                      </div>
                      <div className="icon-content">
                        <NavLink to="/services/detail"><h4 className="sx-tilte">Cloud Services</h4></NavLink>
                        <p>Offering cloud computing solutions such as cloud migration, cloud infrastructure setup, cloud-based storage, backup, disaster recovery,
                                                    and cloud security services. This also includes platforms like Amazon Web Services (AWS), Microsoft Azure, Google Cloud Platform, and more.</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Block Four */}
                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-icon-bx-1">
                    <div className="sx-icon-box-wraper">
                      <div className="sx-icon-bx-icon ">
                        <span className="sx-text-primary"><i className="flaticon-profit" /></span>
                      </div>
                      <div className="icon-content">
                        <NavLink to="/services/detail"><h4 className="sx-tilte">Cyber security</h4></NavLink>
                        <p>Implementing comprehensive cyber security measures to protect clients' data, networks, and systems from cyber threats. This includes
                                                    security assessments, penetration testing, firewall setup, antivirus solutions, endpoint security, data encryption, and cyber security training.</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Block Five */}
                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-icon-bx-1">
                    <div className="sx-icon-box-wraper">
                      <div className="sx-icon-bx-icon ">
                        <span className="sx-text-primary"><i className="flaticon-chart" /></span>
                      </div>
                      <div className="icon-content">
                        <NavLink to="/services/detail"><h4 className="sx-tilte">Managed IT Services</h4></NavLink>
                        <p>Providing ongoing monitoring, maintenance, and support for clients' IT infrastructure, including servers, networks,
                                                    databases, and devices. This may include remote monitoring, help desk support, patch management, IT asset management, and IT support services.</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Block SIx */}
                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-icon-bx-1">
                    <div className="sx-icon-box-wraper">
                      <div className="sx-icon-bx-icon ">
                        <span className="sx-text-primary"><i className="flaticon-parking" /></span>
                      </div>
                      <div className="icon-content">
                        <NavLink to="/services/detail"><h4 className="sx-tilte">Web Development and Design</h4></NavLink>
                        <p>Creating visually appealing and functional websites, web applications, and e-commerce platforms. This involves web design,
                                                    front-end development, back-end development, content management systems (CMS), responsive design, and user experience (UX) optimisation.</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Block SEVEN */}
                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-icon-bx-1">
                    <div className="sx-icon-box-wraper">
                      <div className="sx-icon-bx-icon ">
                        <span className="sx-text-primary"><i className="flaticon-parking" /></span>
                      </div>
                      <div className="icon-content">
                        <NavLink to="/services/detail"><h4 className="sx-tilte">IT Support and Help desk</h4></NavLink>
                        <p>Providing technical support, troubleshooting, and help desk services to resolve IT issues and ensure smooth operations.
                                                    This includes remote support, on-site support, IT ticketing systems, user training, and IT documentation.</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Block EIGHT */}
                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-icon-bx-1">
                    <div className="sx-icon-box-wraper">
                      <div className="sx-icon-bx-icon ">
                        <span className="sx-text-primary"><i className="flaticon-parking" /></span>
                      </div>
                      <div className="icon-content">
                        <NavLink to="/services/detail"><h4 className="sx-tilte">Digital Marketing and SEO</h4></NavLink>
                        <p>Offering digital marketing services such as search engine optimisation (SEO), pay-per-click (PPC) advertising, social media marketing,
                                                    content marketing, email marketing, and digital analytics to help clients enhance their online presence and reach their target audience.</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Block NINE */}
                <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                  <div className="sx-icon-bx-1">
                    <div className="sx-icon-box-wraper">
                      <div className="sx-icon-bx-icon ">
                        <span className="sx-text-primary"><i className="flaticon-parking" /></span>
                      </div>
                      <div className="icon-content">
                        <NavLink to="/services/detail"><h4 className="sx-tilte">Database Management Services</h4></NavLink>
                        <p>Designing, implementing, and managing databases for clients, including relational databases (e.g., MySQL, PostgreSQL, SQL Server) and NoSQL databases
                                                    (e.g., MongoDB, Cassandra). Services may include database setup, optimisation, backup and recovery, data migration, and database performance tuning.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionWhyChooseUs1;