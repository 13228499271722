import ItodoImage from '../../../elements/itodo-img';
import { NavLink } from 'react-router-dom';

function SectionBlogs1() {
  return (
    <>
      <div className="section-full p-t110 p-b80 sx-bg-white sx-latest-blog-outer wow fadeInDown" data-wow-duration="1000ms">
        <div className="container">
          {/* TITLE START */}
          <div className="section-head center">
            <div className="sx-head-s-title">Our Blogs</div>
            <div className="sx-head-l-title">
              <h2 className="sx-title">Our Latest News</h2>
            </div>
          </div>
          {/* TITLE END */}
          <div className="section-content">
            <div className="owl-carousel sx-latest-blog-1-carousel m-b30">
              {/*One block*/}
              <div className="item">
                <div className="sx-latest-post-st-1">
                  <div className="sx-post-media sx-img-effect img-reflection">
                    <NavLink to="/blogs/detail"><ItodoImage src="images/blog/blog-grid/blog-pic1.jpg" alt="" /></NavLink>
                  </div>
                  <div className="sx-post-info p-t30">
                    <div className="sx-post-meta ">
                      <ul>
                        <li className="post-date"><NavLink to="/blogs/detail"><i className="fa fa-calendar" />01 Jan 2023</NavLink></li>
                        <li className="post-author"><NavLink to="/blogs/detail"><i className="fa fa-comments" />0 Comments</NavLink></li>
                      </ul>
                    </div>
                    <div className="sx-post-title ">
                      <h4 className="post-title"><NavLink to="/blogs/detail">Standard size of business agency kal Consulat Latest Management.</NavLink></h4>
                    </div>
                    <div className="sx-post-readmore">
                      <NavLink to="/blogs/detail" title="READ MORE" rel="bookmark" className="site-button icon"><i className="fa  fa-long-arrow-right" />Read More</NavLink>
                    </div>
                  </div>
                </div>
              </div>
              {/*One two*/}
              <div className="item">
                <div className="sx-latest-post-st-1">
                  <div className="sx-post-media sx-img-effect img-reflection">
                    <NavLink to="/blogs/detail"><ItodoImage src="images/blog/blog-grid/blog-pic2.jpg" alt="" /></NavLink>
                  </div>
                  <div className="sx-post-info p-t30">
                    <div className="sx-post-meta ">
                      <ul>
                        <li className="post-date"><NavLink to="/blogs/detail"><i className="fa fa-calendar" />01 Jan 2023</NavLink></li>
                        <li className="post-author"><NavLink to="/blogs/detail"><i className="fa fa-comments" />0 Comments</NavLink></li>
                      </ul>
                    </div>
                    <div className="sx-post-title ">
                      <h4 className="post-title"><NavLink to="/blogs/detail">The Value of a Trusted Partner for Service Provider Program Management.</NavLink></h4>
                    </div>
                    <div className="sx-post-readmore">
                      <NavLink to="/blogs/detail" title="READ MORE" rel="bookmark" className="site-button icon"><i className="fa  fa-long-arrow-right" />Read More</NavLink>
                    </div>
                  </div>
                </div>
              </div>
              {/*One three*/}
              <div className="item">
                <div className="sx-latest-post-st-1">
                  <div className="sx-post-media sx-img-effect img-reflection">
                    <NavLink to="/blogs/detail"><ItodoImage src="images/blog/blog-grid/blog-pic3.jpg" alt="" /></NavLink>
                  </div>
                  <div className="sx-post-info p-t30">
                    <div className="sx-post-meta ">
                      <ul>
                        <li className="post-date"><NavLink to="/blogs/detail"><i className="fa fa-calendar" />01 Jan 2023</NavLink></li>
                        <li className="post-author"><NavLink to="/blogs/detail"><i className="fa fa-comments" />0 Comments</NavLink></li>
                      </ul>
                    </div>
                    <div className="sx-post-title ">
                      <h4 className="post-title"><NavLink to="/blogs/detail">Technologent Announced as Finalists at 2022 Best IT County Awards.</NavLink></h4>
                    </div>
                    <div className="sx-post-readmore">
                      <NavLink to="/blogs/detail" title="READ MORE" rel="bookmark" className="site-button icon"><i className="fa  fa-long-arrow-right" />Read More</NavLink>
                    </div>
                  </div>
                </div>
              </div>
              {/*One For*/}
              <div className="item">
                <div className="sx-latest-post-st-1">
                  <div className="sx-post-media sx-img-effect img-reflection">
                    <NavLink to="/blogs/detail"><ItodoImage src="images/blog/blog-grid/blog-pic1.jpg" alt="" /></NavLink>
                  </div>
                  <div className="sx-post-info p-t30">
                    <div className="sx-post-meta ">
                      <ul>
                        <li className="post-date"><NavLink to="/blogs/detail"><i className="fa fa-calendar" />01 Jan 2023</NavLink></li>
                        <li className="post-author"><NavLink to="/blogs/detail"><i className="fa fa-comments" />0 Comments</NavLink></li>
                      </ul>
                    </div>
                    <div className="sx-post-title ">
                      <h4 className="post-title"><NavLink to="/blogs/detail">Standard size of business agency kal Consulat Latest Management.</NavLink></h4>
                    </div>
                    <div className="sx-post-readmore">
                      <NavLink to="/blogs/detail" title="READ MORE" rel="bookmark" className="site-button icon"><i className="fa  fa-long-arrow-right" />Read More</NavLink>
                    </div>
                  </div>
                </div>
              </div>
              {/*One Five*/}
              <div className="item">
                <div className="sx-latest-post-st-1">
                  <div className="sx-post-media sx-img-effect img-reflection">
                    <NavLink to="/blogs/detail"><ItodoImage src="images/blog/blog-grid/blog-pic2.jpg" alt="" /></NavLink>
                  </div>
                  <div className="sx-post-info p-t30">
                    <div className="sx-post-meta ">
                      <ul>
                        <li className="post-date"><NavLink to="/blogs/detail"><i className="fa fa-calendar" />01 Jan 2023</NavLink></li>
                        <li className="post-author"><NavLink to="/blogs/detail"><i className="fa fa-comments" />0 Comments</NavLink></li>
                      </ul>
                    </div>
                    <div className="sx-post-title ">
                      <h4 className="post-title"><NavLink to="/blogs/detail">The Value of a Trusted Partner for Service Provider Program Management.</NavLink></h4>
                    </div>
                    <div className="sx-post-readmore">
                      <NavLink to="/blogs/detail" title="READ MORE" rel="bookmark" className="site-button icon"><i className="fa  fa-long-arrow-right" />Read More</NavLink>
                    </div>
                  </div>
                </div>
              </div>
              {/*One Six*/}
              <div className="item">
                <div className="sx-latest-post-st-1">
                  <div className="sx-post-media sx-img-effect img-reflection">
                    <NavLink to="/blogs/detail"><ItodoImage src="images/blog/blog-grid/blog-pic3.jpg" alt="" /></NavLink>
                  </div>
                  <div className="sx-post-info p-t30">
                    <div className="sx-post-meta ">
                      <ul>
                        <li className="post-date"><NavLink to="/blogs/detail"><i className="fa fa-calendar" />01 Jan 2023</NavLink></li>
                        <li className="post-author"><NavLink to="/blogs/detail"><i className="fa fa-comments" />0 Comments</NavLink></li>
                      </ul>
                    </div>
                    <div className="sx-post-title ">
                      <h4 className="post-title"><NavLink to="/blogs/detail">Technologent Announced as Finalists at 2022 Best IT County Awards.</NavLink></h4>
                    </div>
                    <div className="sx-post-readmore">
                      <NavLink to="/blogs/detail" title="READ MORE" rel="bookmark" className="site-button icon"><i className="fa  fa-long-arrow-right" />Read More</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionBlogs1;